import React from "react";

const Homepage = () => {
  return (
    <div>
      Create a room using /roomID<br></br>
      Example: moviepanda.netlify.app/wefiw32AFC
    </div>
  );
};

export default Homepage;